export class PaymentRequest {
    destination: string;
    memo: string;
    assetIssuer: string;
    assetCode: string;
    amount: string;
    transaction: string;
    transactionSignature: string;
    transactionId: string;
    networkPassPhrase: string;
    destinationFirstName: string;
    destinationLastName: string;
    destinationThumbnail: string;
}
