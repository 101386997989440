export class Constants {
    public static PUBLIC_KEY_HEADER = 'X-BANTUPAY-PUBLIC-KEY';
    public static SIGNATURE_HEADER = 'X-BANTUPAY-SIGNATURE';
    public static RAPID_API_KEY = '4b42e267aemshbf871602ede8c89p1eb9dfjsn34548e686b0f';
    public static RAPID_API_HOST = 'privatix-temp-mail-v1.p.rapidapi.com';
    public static MAILSAC_API_KEY_LABEL = 'apiKey';
    public static MAILSAC_API_KEY_VALUE = 'k_b2tAAMYvOuUo1OAqv9JJga0M1e1qPv7JWMvm8vtkMpQ19a8';
    public static MAILSAC_POP3_SERVER = 'poppy.mailsac.com';
    public static MAILSAC_POPD_PORT = '110';
}
