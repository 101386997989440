import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Account } from '../requests/account.request';
import { Constants } from '../../utilities/constants';
import {PaymentRequest} from '../requests/payment.request';
import { PaymentHistory} from '../responses/payment-history.response';
import {Email} from '../models/email.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  private getBaseUrl(): string {
    return environment.baseUrl;
  }

  private getRapidAPIBaseUrl(): string {
    return environment.rapidApiBaseUrl;
  }

  private getMailSacAPIBaseUrl(): string {
    return environment.mailsacApiUrl;
  }

  createUser(account: Account, publicKey: string, signature: string): Observable<any> {
    return this.http.post<Observable<any>>(`${this.getBaseUrl()}/v2/users`, account, this.buildBantuPayHeader(publicKey, signature));
  }

  fetchUserInformation(username: string, publicKey: string, signature: string): Observable<any>{
    return this.http.get<Observable<any>>(`${this.getBaseUrl()}/v2/users/${username}`, this.buildBantuPayHeader(publicKey, signature));
  }

  fetchPaymentHistory(username: string, publicKey: string, signature: string): Observable<any>{
    return this.http.get<any>(`${this.getBaseUrl()}/v2/users/${username}/payments`, this.buildBantuPayHeader(publicKey, signature));
  }

  makePayment(username: string, paymentRequest: PaymentRequest, publicKey: string, signature: string): Observable<any>{
    return this.http.post<Observable<any>>(`${this.getBaseUrl()}/v2/users/${username}/payments`, paymentRequest, this.buildBantuPayHeader(publicKey, signature));
  }

  getEmails(emailAddress: string): Observable<any> {
    return this.http.get<Observable<any>>(`${this.getMailSacAPIBaseUrl()}api/addresses/${emailAddress}/messages`, this.buildJSONMailsacHeader());
  }

  getEmailMetadata(messageId: string, emailAddress: string): Observable<any> {
    return this.http.get<Observable<any>>(`${this.getMailSacAPIBaseUrl()}api/raw/${emailAddress}/${messageId}`, this.buildPlainMailsacHeader());
  }

  private buildBantuPayHeader(publicKey: string, signature: string): any {
    const headerDict = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-BANTUPAY-SIGNATURE': signature,
      'X-BANTUPAY-PUBLIC-KEY': publicKey
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return requestOptions;
  }

  private buildJSONMailsacHeader(): any {
    const headerDict = {
      'Mailsac-Key': Constants.MAILSAC_API_KEY_VALUE
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return requestOptions;
  }

  private buildPlainMailsacHeader(): any {
    const headerDict = {
      accept : 'text/plain',
      'Content-Type': 'text/plain; charset=utf-8',
      'Mailsac-Key': Constants.MAILSAC_API_KEY_VALUE
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
      responseType: 'text' as 'json'
    };
    return requestOptions;
  }
}
