export class Account {
    username: string = null;
    email: string = null;
    lastName: string = null;
    firstName: string = null;
    middleName: string = null;
    gender: string = null;
    mobile: string = null;
    phoneNumber: string = null;
    telegram: string = null;
    twitter: string = null;
    imageThumbnail: string = null;
    verificationCode: string = null;
    publicKey: string = null;
    secretKey: string = null;
    balances: Balance;
}

export class Balance {
    claimed: Asset[];
    unclaimed: Asset[];
}

export class Asset {
    assetCode: string;
    assetIssuer: string;
    amount: string;
}
