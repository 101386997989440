<!-- header -->
<!-- <div class="navbar header"><span class="navbar-brand"><a href="/"><span> <img
          src="../../../assets/images/bantu_logo_white.png" height="41" width="41" alt="Bantu Logo">
      </span></a><span class="so-logo__separator"></span><a href="/"><span class="brand-text">BantuPay Tests
        App</span></a></span><button type="button" class="navbar-toggle collapsed"><span class="sr-only">Toggle
      navigation</span><span class="icon-bar"></span><span class="icon-bar"></span><span
      class="icon-bar"></span></button></div> -->
<nav class="navbar header">

  <!-- logo -->
  <div class="navbar-brand">
    <a class="navbar-item" href="/">
      <img src="../../../assets/images/bantupay_logo.png" height="200" alt="BantuPay Logo">
    </a>
  </div>
</nav>

<section class="is-info is-fullheight is-bold">
  <div class="hero-body">
    <div class="container">
      <div class="inline">
        <h1 class="title" style="display: inline;">BantuPay Tests Runner</h1>
        <button type="button" class="button is-danger is-large" style="display: inline;margin-left: 2em;vertical-align: middle;" (click)="setupTests()" [disabled]="testsInProgress">Run Tests</button>
      </div>
      <div>

        <div id="test-summary">

          <div class="container">

            <table class="table">

              <tr class="th">
                <th>Test Title</th>
                <th>Test Description</th>
                <th>Status</th>
                <th>Running</th>
                <th>Summary</th>
              </tr>

              <tr *ngFor="let item of integrationTestList">
                <td>{{item.testTitle}}</td>
                <td>{{item.testDescription}}</td>
                <td>{{item.status}}</td>
                <td><progress *ngIf="item.status" class="progress is-small is-primary" max="100">15%</progress></td>
                <td>{{item.completionSummary}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>