import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'ngx-alerts';

@Injectable()
export class ReusableService {
    constructor(private router: Router, private http: HttpClient, private alertService: AlertService) {
    }

    isNullOrUndefined(value: any): boolean {
        return value === null || value === undefined || value === 'null' || value === 'undefined';
    }

    showErrorMessage(errorMessage: string): void {
        if (!this.isNullOrUndefined(errorMessage) && errorMessage.trim().length > 0) {
            if (!this.isNullOrUndefined(this.alertService)) {
                this.alertService.warning(errorMessage);
            }
        }
    }

    showSuccessMessage(message: string): void {
        if (!this.isNullOrUndefined(message) && message.trim().length > 0) {
            if (!this.isNullOrUndefined(this.alertService)) {
                this.alertService.success(message);
            }
        }
    }
}