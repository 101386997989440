import { Component, OnDestroy, OnInit } from '@angular/core';
import BantuSDK from 'bantupay-sdk';
import { Account, Balance } from '../api/requests/account.request';
import { PaymentRequest } from '../api/requests/payment.request';
import { ApiService } from '../api/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReusableService } from 'src/app/utilities/reusable.service';
import { Email } from '../api/models/email.model';
import { IntegrationTest } from '../api/models/integration-test.model';
import { interval, Subscription } from 'rxjs';
import { title } from 'process';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  private source = interval(5000);

  private prefundedAccountList: Account[] = [];

  private profileCheckMap: Map<string, Balance> = new Map();
  private paymentCheckMap: Map<string, string> = new Map();
  private historyCheckMap: Map<string, string> = new Map();

  integrationTestList: IntegrationTest[] = [];
  testsInProgress: boolean = false;

  constructor(private apiService: ApiService, private ngxService: NgxUiLoaderService, private reusableService: ReusableService) {

  }

  ngOnInit(): void {
    this.initializeTestAccounts();
    this.initializeIntegrationTestList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initializeTestAccounts(): void {
    const fundedAccountOne = new Account();
    fundedAccountOne.username = 'fundedaccountone';
    fundedAccountOne.publicKey = 'GBRDUMLAHLFP3LS7LDS3GUIHSUPLDGJEBVQM447FAQUJ22DVSM3E3H6T';
    fundedAccountOne.secretKey = 'SBANLMWCJ7Y77A6IL4T6YWCNOLWEUNBK67WN2FHEVGWJPGWT33PE6ZZS';

    const fundedAccountTwo = new Account();
    fundedAccountTwo.username = 'fundedaccounttwo';
    fundedAccountTwo.publicKey = 'GBOLBPCC4OB7UY5AU5OIZ4LUSJDDKLXZJJLM6BKJGN3VLOVZFN5NNB37';
    fundedAccountTwo.secretKey = 'SB5S7BKFNGOODE2PBB5BYCSZ3I3EQSRVN4TVFDUDFMP5I6P2GWEVD6GM';

    this.prefundedAccountList.push(fundedAccountOne);
    this.prefundedAccountList.push(fundedAccountTwo);
  }

  private initializeIntegrationTestList() : void {
    this.integrationTestList.push(this.buildIntegrationTestObject(1, "Test to fetch User Information", `Fetch User Information for ${this.prefundedAccountList[0].username}`,  null, false, null));
    this.integrationTestList.push(this.buildIntegrationTestObject(2, "Test to fetch User Information", `Fetch User Information for ${this.prefundedAccountList[1].username}`, null, false, null));
    this.integrationTestList.push(this.buildIntegrationTestObject(3, "Send Payment (100 XBN)", `Test to Send 100 XBN from ${this.prefundedAccountList[0].username} to ${this.prefundedAccountList[1].username}`, null, false, null));
    this.integrationTestList.push(this.buildIntegrationTestObject(4, "Send Payment (100 XBN)", `Test to Send 100 XBN from ${this.prefundedAccountList[1].username} to ${this.prefundedAccountList[0].username}`, null, false, null));
    this.integrationTestList.push(this.buildIntegrationTestObject(5, "Fetch Payment History", `Test to fetch User Payment History to confirm Payment was sent successfully to ${this.prefundedAccountList[0].username}`, null, false, null));
    this.integrationTestList.push(this.buildIntegrationTestObject(6, "Fetch Payment History", `Test to fetch User Payment History to confirm Payment was sent successfully to ${this.prefundedAccountList[1].username}`, null, false, null));
  }

  private buildIntegrationTestObject(id: number, testTitle: string, testDescription: string, completionSummary: string, status: boolean, error: string): IntegrationTest {
    const integrationTest = new IntegrationTest();
    integrationTest.testId = id;
    integrationTest.testTitle = testTitle;
    integrationTest.testDescription = testDescription;
    integrationTest.completionSummary = completionSummary;
    integrationTest.status = status;
    integrationTest.error = error;

    return integrationTest;
  }

  private startIntegrationTest(testId: number): void {
    const integrationTest = this.integrationTestList.filter(item => item.testId === testId)[0];
    integrationTest.status = true;
    integrationTest.completionSummary = 'In progress';
  }

  private completeIntegrationTestWithSuccess(testId: number): void {
    const integrationTest = this.integrationTestList.filter(item => item.testId === testId)[0];
    integrationTest.status = false;
    integrationTest.completionSummary = 'Completed';
  }

  private completeIntegrationTestWithError(testId: number): void {
    const integrationTest = this.integrationTestList.filter(item => item.testId === testId)[0];
    integrationTest.status = false;
    this.testsInProgress = false;
    integrationTest.completionSummary = 'Failed';
  }

  setupTests(): void {
    this.testsInProgress = true;
    this.prefundedAccountList.forEach((account, index) => {
      this.fetchUserInformation(account, index);
    });
  }

  private fetchUserInformation(account: Account, index: number): void {
    this.startIntegrationTest(index == 0 ? 1 : 2);
    const signature = BantuSDK.signHTTP(`/v2/users/${account.username}`, null, account.secretKey);
    this.apiService.fetchUserInformation(account.username, account.publicKey, signature)
      .subscribe(data => {
        this.completeIntegrationTestWithSuccess(1);
        account.firstName = data.firstName;
        account.email = data.email;
        account.gender = data.gender;
        account.firstName = data.firstName;
        account.lastName = data.lastName;
        account.mobile = data.mobile;
        account.balances = data.balances;
        const indexOfItemInArray = this.prefundedAccountList.findIndex(q => q.username === account.username);
        this.prefundedAccountList.splice(indexOfItemInArray, 1, account);
        this.profileCheckMap.set(account.publicKey, account.balances);
        this.completeIntegrationTestWithSuccess(index == 0 ? 1 : 2);
        if (this.profileCheckMap != null && this.profileCheckMap.size === 2) {
          this.testSendingPayments(this.prefundedAccountList);
        }
      }, err => {
        const error = err as HttpErrorResponse;
        console.log(error);
        this.completeIntegrationTestWithError(index == 0 ? 1 : 2);
      });
  }

  private testSendingPayments(accounts: Account[]): void {
    this.makePayment(accounts[0], accounts[1], false);
  }

  private makePayment(senderAccount: Account, recipientAccount: Account, reverseTest: boolean): void {
    const paymentRequest = new PaymentRequest();
    paymentRequest.destination = recipientAccount.username;
    paymentRequest.amount = '100';
    paymentRequest.memo = 'Payment Test';
    const signature = BantuSDK.signHTTP(`/v2/users/${senderAccount.username}/payments`, paymentRequest, senderAccount.secretKey);

    this.startIntegrationTest(reverseTest == true ? 4 : 3);
    this.apiService.makePayment(senderAccount.username, paymentRequest, senderAccount.publicKey, signature)
      .subscribe(data => {
        paymentRequest.networkPassPhrase = data.networkPassPhrase;
        paymentRequest.transaction = data.transaction;
        const transactionSignature = BantuSDK.signTxn(senderAccount.secretKey, paymentRequest.transaction, paymentRequest.networkPassPhrase);
        paymentRequest.transactionSignature = transactionSignature;
        const updatedSignature = BantuSDK.signHTTP(`/v2/users/${senderAccount.username}/payments`, paymentRequest, senderAccount.secretKey);
        this.apiService.makePayment(senderAccount.username, paymentRequest, senderAccount.publicKey, updatedSignature)
          .subscribe(confirmedPayment => {
            if (confirmedPayment.transactionId !== null) {
              this.paymentCheckMap.set(senderAccount.username, confirmedPayment.transactionId);
              this.completeIntegrationTestWithSuccess(reverseTest == true ? 4 : 3);
              if (this.paymentCheckMap != null && this.paymentCheckMap.size === 2) {
                this.testPaymentHistory(this.prefundedAccountList);
              } else {
                this.makePayment(recipientAccount, senderAccount, true);
              }
            }
          }, err => {
            const error = err as HttpErrorResponse;
            console.log(error);
            this.ngxService.stopAll();
            this.completeIntegrationTestWithError(reverseTest == true ? 4 : 3);
          });
      }, err => {
        const error = err as HttpErrorResponse;
        console.log(error);
        this.ngxService.stopAll();
        this.reusableService.showErrorMessage(JSON.stringify(error));
        this.completeIntegrationTestWithError(reverseTest == true ? 4 : 3);
      });
  }

  private testPaymentHistory(accounts: Account[]): void {
    accounts.forEach((account, index) => {
      this.fetchPaymentHistory(account, index);
    });
  }

  private fetchPaymentHistory(account: Account, index: number): void {
    const signature = BantuSDK.signHTTP(`/v2/users/${account.username}/payments`, null, account.secretKey);
    //this.ngxService.start();
    this.startIntegrationTest(index == 0 ? 5 : 6);
    this.apiService.fetchPaymentHistory(account.username, account.publicKey, signature)
      .subscribe(data => {
        const transactionId = this.paymentCheckMap.get(account.username);
        const paymentHistoryForTransaction = data.payments.filter(payment => payment.transactionID === transactionId)[0];
        if (paymentHistoryForTransaction != null) {
          this.historyCheckMap.set(account.username, transactionId);
          this.completeIntegrationTestWithSuccess(index == 0 ? 5 : 6);
          //this.reusableService.showSuccessMessage(`Payment for ${paymentHistoryForTransaction.amount} with Transaction ID: ${transactionId} confirmed`);
          if (this.historyCheckMap != null && this.historyCheckMap.size === 2) {
            this.testsInProgress = false;
            this.ngxService.stopAll();
          }
        }else{
          this.completeIntegrationTestWithError(index == 0 ? 5 : 6);
        }
      }, err => {
        const error = err as HttpErrorResponse;
        console.log(error);
        this.ngxService.stopAll();
        this.reusableService.showErrorMessage(JSON.stringify(error));
        this.completeIntegrationTestWithError(index == 0 ? 5 : 6);
      });
  }

  private createUserWithExistingKeyPair(account: Account): void {
    const signature = BantuSDK.signHTTP('/v2/users', account, account.secretKey);
    this.ngxService.start();
    this.apiService.createUser(account, account.publicKey, signature)
      .subscribe(data => {
        console.log(data);
        this.reusableService.showSuccessMessage(data.message);
        // this.createEmailCheckerSubscription(this.signupRequest.email);
      }, err => {
        const error = err as HttpErrorResponse;
        console.log(error);
        this.ngxService.stopAll();
        this.reusableService.showErrorMessage(JSON.stringify(error));
      });
  }

  private createAccount(): void {
    const keypair = BantuSDK.createAccount();

    const newAccount = new Account();
    newAccount.email = 'bantupay_q15go@mailsac.com';
    newAccount.username = 'testuser';
    newAccount.mobile = '+2348088111734';
    newAccount.lastName = 'Test';
    newAccount.firstName = 'Test';
    newAccount.publicKey = keypair.publicKey;
    newAccount.secretKey = keypair.secret;

    const signature = BantuSDK.signHTTP('/v2/users', newAccount, newAccount.secretKey);
    this.ngxService.start();
    this.apiService.createUser(newAccount, newAccount.publicKey, signature)
      .subscribe(data => {
        console.log(data);
        this.reusableService.showSuccessMessage(data.message);
        this.createEmailCheckerSubscription(newAccount);
      }, err => {
        const error = err as HttpErrorResponse;
        console.log(error);
        this.ngxService.stopAll();
        this.reusableService.showErrorMessage(JSON.stringify(error));
      });
  }

  private createEmailCheckerSubscription(account: Account): void {
    this.subscription = this.source.subscribe(val => {
      this.getUserEmails(account);
    });
  }

  private getUserEmails(account: Account): void {
    this.apiService.getEmails(account.email)
      .subscribe(data => {
        const emails: Email[] = [];
        if (data !== null && data.length > 0) {
          data.forEach((email) => {
            emails.push(email);
          });
          console.log(emails);
          const mostRecentEmail = emails[0];
          const date = Date.parse(mostRecentEmail.received);
          console.log(date.toString());
          console.log(mostRecentEmail);
          this.getEmailMessage(mostRecentEmail._id, account);
        }
      }, err => {
        this.ngxService.stopAll();
        const error = err as HttpErrorResponse;
        console.log(error);
        this.reusableService.showErrorMessage(JSON.stringify(error));
      });
  }

  private getEmailMessage(messageId: string, account: Account): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
    this.apiService.getEmailMetadata(messageId, account.email)
      .subscribe(data => {
        const emailMetaData = data;
        const otpRegexCode = new RegExp('is \\d{6}');
        console.log(otpRegexCode.test(emailMetaData));
        const match = otpRegexCode.exec(emailMetaData);
        const otp = match[0].substr(3);
        console.log(otp);
        this.verifyAccount(otp, account);
        // console.log(emailMetaData.match('\\d{6}'));
        this.reusableService.showSuccessMessage('Verification Email received');
      }, err => {
        this.ngxService.stopAll();
        const error = err as HttpErrorResponse;
        console.log(error);
        this.reusableService.showErrorMessage(JSON.stringify(error));
      });
  }

  private verifyAccount(otpCode: string, account: Account): void {
    account.verificationCode = otpCode;
    const signature = BantuSDK.signHTTP('/v2/users', JSON.stringify(account), account.secretKey);
    this.ngxService.start();
    this.apiService.createUser(account, account.publicKey, signature)
      .subscribe(data => {
        console.log(data);
        this.reusableService.showSuccessMessage(data.message);
      }, err => {
        const error = err as HttpErrorResponse;
        console.log(error);
        this.ngxService.stopAll();
        this.reusableService.showErrorMessage(JSON.stringify(error));
      });
  }

  /*
    Payment tests should include multiple scenarios like receivers existing or not, funded or not, trusting a custom asset or not.
  */
}
